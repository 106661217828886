export const environment = {
  firebase: {
    apiKey: 'AIzaSyDZuKWWoznEQ_UgFd5Y_Jspb6Ii6Mo1uNU',
    authDomain: 'futura-prod-80f4e.firebaseapp.com',
    projectId: 'futura-prod-80f4e',
    storageBucket: 'futura-prod-80f4e.appspot.com',
    messagingSenderId: '830469910330',
    appId: '1:830469910330:web:deb6d0114d5d95f41e2a03',
    measurementId: 'G-2RV29TT2LB',
    databaseURL: 'https://futura-prod-80f4e-default-rtdb.europe-west1.firebasedatabase.app',
  },
  locale: 'it',
  language: 'it',
  production: true,
  environment_type: 'prod',
  be_url: 'futura-be',
  alice_api: 'alice-be',
  fe_url: 'https://med.futura.study',
  google_analytics_key: 'G-2RV29TT2LB',
  sentry_dsn: 'https://88d7bb42400b4f678ea85c31d8b24ff4@o957191.ingest.sentry.io/4504554338779136',
  userpilot_token: 'NX-b3358db6',
  platform: 'forze_armate',
  pn_public_key: 'BA6sX3R8g6tRN6Gdd5j69HNRpLxy-A2i5PxERSDjYwD7f0knxGHPhcw4KGt5o3LnwQd-lKCAJsrcyPG-oCIgO8Q',
  maintance_mode_url: 'https://futuraimagebucket.s3.eu-central-1.amazonaws.com/fut-maintenance/maintenance.html',
  awsmobile: {
    aws_project_region: 'eu-central-1',
    aws_cognito_identity_pool_id: 'eu-central-1:c640b8fa-2f8c-4692-a336-cdabdca5a430',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_Z4JMTVXh6',
    aws_user_pools_web_client_id: '4nt6i5e4mg8ldj59mrt2e2bj55',
    oauth: {
      domain: 'futura-prod-dev.auth.eu-central-1.amazoncognito.com',
      scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://med.futura.study/',
      redirectSignOut: 'https://med.futura.study/logout/',
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
  },
  zoom: {
    api_key: 'PNXjsnTvTwGULnG1bzKwpg',
  },
  google: {
    maps_key: 'AIzaSyAXuCTGA2Go9b5fevAQhbC3LHqnekqXpfI',
    tag_manager: 'GTM-MJ22W8Q',
  },
};
